'use client'

import { useMemo } from 'react'
import { Circle, HStack, Text } from '@/components/Chakra'
import { getColorMatchResult, getTextMatchResult } from '@/utilities/pages/tables/matchResults'
import { getLastResults } from '@/utilities/getLastResults'
import { Result } from '@/types/results'

const LastMatches = ({ results = [], team = '' }: { results: Result[], team: string }) => {
  const lastResults = useMemo(() => getLastResults(results, team), [results, team])
  
  const circleMatches = lastResults.reverse().map((match: Result) => {
    const colorMatchResult = getColorMatchResult(team, match.teams, match.fixture.status)
    const textMatchResult = getTextMatchResult(team, match.teams, match.fixture.status)

    return (
      <Circle key={match.fixture.id} size="25px" bgColor={colorMatchResult}>
        <Text fontSize="sm" color="white">{textMatchResult}</Text>
      </Circle>
    )
  })
  
  if ((!results || !team) || lastResults.length === 0) {
    return null
  }

  return (
    <HStack justifyContent="center" spacing={2}>
      {circleMatches}
    </HStack>
  )
}

export default LastMatches