import useSWR from 'swr'
import _ from 'lodash'
import { getCurrentYear } from '@/utilities/calendar-dates'
import { LeaguesAvailable } from '@/types/leagues'
import { Result } from '@/types/results'

type Results = {
  league?: number
  season?: number
  team?: number
}

const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => res.json())
const currentYear = getCurrentYear()

export function useResults({ league = LeaguesAvailable.PRIMERA_DIVISION_ID, season = currentYear, team } : Results ) {
  const getUrl = (): string => {
    if (team) {
      return `/api/results?league=${league}&season=${season}&team=${team}`
    }

    return `/api/results?league=${league}&season=${season}`
  }
  
  const { data } = useSWR(getUrl, fetcher)

  return {
    results: _.get(data, 'data.response')
  }
}