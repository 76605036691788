'use client'

import Image from 'next/image'
import NextLink from 'next/link'
import { useState } from 'react'
import { Button, Box, Center, Circle, HStack, Link, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery } from '@/components/Chakra'
import { motion, AnimatePresence } from 'framer-motion'
import Icon from '@/components/Icon'

import { Standing } from '@/types/standings'
import { useStandings } from '@/hooks/useStandings'

const Status = ({ status }: { status: string }) => {
  if (status === "up") {
    return <Center width="12px" color="green.400"><Icon name="chevron-up" strokeWidth="3" /></Center>
  }

  if (status === "down") {
    return <Center width="12px" color="red.400"><Icon name="chevron-down" strokeWidth="3" /></Center>
  }

  if (status === "same") {
    return <Center width="12px"><Circle size="1.5" bgColor="gray.400" /></Center>
  }

  return null;
};

const TablesPositions = () => {
  const [breakpointMd] = useMediaQuery("(min-width: 768px)", { ssr: true, fallback: true })
  const [expanded, setExpanded] = useState(false)

  const { primeraDivision } = useStandings({})

  const visibleTeams = expanded ? primeraDivision : primeraDivision?.slice(0, 5);

  function toggleExpansion() {
    setExpanded(!expanded);
  };

  return (
    <Stack as="section" width="full" bgColor="gray.700" border="1px solid" borderColor="green.400" borderRadius="lg" overflow="hidden" spacing={0}>
      <Box borderBottom="1px solid" borderColor="green.400" bgColor="green.700" p={3}>
        <Text fontSize={{ base: "md", md: "lg" }} fontWeight={600} color="white" textAlign="center">Primera Divisón</Text>
      </Box>

      <TableContainer>
        <Table overflow="hidden">
          <Thead>
            <Tr>
              <Th scope="col" p={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="green.400" width="30px">Pos.</Th>
              <Th scope="col" px={0} py={3} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" borderColor="green.400" width="50%">Club</Th>
              <Th scope="col" px={3} py={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="green.400" width={{ md: "45px" }}>Pts</Th>
              <Th scope="col" px={3} py={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="green.400" width={{ md: "45px" }}>J</Th>
              <Th scope="col" px={3} p={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="green.400" width={{ md: "35px" }}>DIF</Th>
            </Tr>
          </Thead>

          <Tbody>
            <AnimatePresence initial={false}>
              {visibleTeams?.map((item: Standing, index: number) => {
                return (
                  <motion.tr key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} whileHover={{ scale: 1.03, backgroundColor: "#2F855A" }}>
                    <Td p={3} fontSize={{ base: "xs", md: "sm" }} borderColor="green.400">
                      <HStack justifyContent="center" spacing={1}>
                        <Text width={{ base: 3, md: 5 }} color="white">{item.rank}</Text>
                        <Status status={item.status} />
                      </HStack>
                    </Td>

                    <Td px={0} py={3} borderColor="green.400">
                      <HStack>
                        <Box as="picture" maxWidth={breakpointMd ? "25px" : "20px"}>
                          <Image
                            src={item.team.logo}
                            width={breakpointMd ? 25 : 20}
                            height={breakpointMd ? 25 : 20}
                            alt={`Logo ${item.team.name}`}
                          />
                        </Box>
                        <Text color="white" fontSize="sm" fontWeight={600} textTransform={{ base: "uppercase", md: "capitalize" }} noOfLines={1}>
                          {breakpointMd ? item.team.name : item.team.name.slice(0,3)}
                        </Text>
                      </HStack>
                    </Td>
                    <Td p={3} fontSize="sm" color="white" fontWeight={600} textAlign="center" borderColor="green.400">{item?.points}</Td>
                    <Td p={3} fontSize="sm" color="white" textAlign="center" borderColor="green.400" >{item?.all?.played}</Td>
                    <Td p={3} fontSize="sm" color="white" textAlign="center" borderColor="green.400" >{item?.goalsDiff}</Td>
                  </motion.tr>
                )
              })}
            </AnimatePresence>
          </Tbody>

          {visibleTeams?.length > 4 && (
            <Tbody>
              <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Td colSpan={12} textAlign="center" borderColor="green.400">
                  <Button
                    onClick={toggleExpansion}
                    variant="link"
                    color="white"
                    _hover={{ color: "yellow.400", textDecoration: "underline" }}
                    rightIcon={expanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
                  >
                    {expanded ? "Ver menos" : "Ver más"}
                  </Button>
                </Td>
              </motion.tr>
            </Tbody>
          )}

          <Tbody bgColor="green.700">
            <Tr>
              <Td colSpan={12} textAlign="center" border="none" p={4}>
                <Link as={NextLink} href="/tablas" fontSize={{ base: "md", md: "sm" }} color="white" fontWeight={600} _hover={{ color: "yellow.400", textDecor: "underline" }}>
                  <HStack justifyContent="center">
                    <Text>Tabla completa</Text>
                    <Icon name="arrow-right" />
                  </HStack>
                </Link>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
};

export default TablesPositions;
