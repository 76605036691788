'use client'

import Image from 'next/image'
import { useState } from 'react'
import { Button, Box, HStack, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery } from '@/components/Chakra'
import { motion, AnimatePresence } from 'framer-motion'
import Icon from '@/components/Icon'
import { Scorers } from '@/types/scorers'
import { useTopScorers } from '@/hooks/useTopScorers'

const TopScorers = () => {
  const [expanded, setExpanded] = useState(false)
  const [breakpointMd] = useMediaQuery("(min-width: 768px)", { ssr: true, fallback: true })

  const { topScorers } = useTopScorers()
  
  const visibleRows = expanded ? topScorers : topScorers?.slice(0, 5)

  function toggleExpansion() {
    setExpanded(!expanded)
  }

  return (
    <Stack as="section" width="full" bgColor="gray.700" border="1px solid" borderColor="gray.500" borderRadius="lg" overflow="hidden" spacing={0}>
      <Box borderBottom="1px solid" borderColor="gray.500" bgColor="gray.800" p={3}>
        <Text fontSize={{ base: "md", md: "lg" }} fontWeight={600} color="green.400" textAlign="center">Goleadores</Text>
      </Box>

      <TableContainer>
        <Table overflow="hidden">
          <Thead>
            <Tr>
              <Th scope="col" p={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="gray.500" width="30px">Club</Th>
              <Th scope="col" px={0} py={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" borderColor="gray.500">Jugador</Th>
              <Th scope="col" px={0} py={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="gray.500" width={{ md: "45px" }}>Goles</Th>
              <Th scope="col" px={{ base: 1, md: 4 }} py={4} fontSize={{ base: "xs", md: "sm" }} color="white" fontWeight={400} textTransform="capitalize" textAlign="center" borderColor="gray.500" width={{ md: "45px" }}>{`(penal)`}</Th>
            </Tr>
          </Thead>

          <Tbody>
            <AnimatePresence initial={false}>
              {visibleRows?.map((item: Scorers, index: number) => {
                const goals = item.statistics.map((item) => item.goals.total);
                const penalties = item.statistics.find((item) => item.penalty.scored)?.penalty.scored || 0;
                const logo = item.statistics.find((item) => item.team.logo)?.team.logo || "";
                const team = item.statistics.map((item) => item.team.name)

                return (
                  <motion.tr key={index} role="group" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} whileHover={{ scale: 1.02, backgroundColor: "#4A5568" }}>
                    <Td px={5} py={3} fontSize={{ base: "xs", md: "sm" }} borderColor="gray.500">
                      <Box as="picture">
                        <Image src={logo} width={25} height={25} alt={`Logo ${team}`} />
                      </Box>
                    </Td>
                    <Td px={0} py={3} borderColor="gray.500">
                      <HStack spacing={1}>
                        <Text color="white" fontSize="sm" fontWeight={600}>
                          {breakpointMd ? item.player.firstname : item.player.firstname.split(" ")[0]}
                        </Text>
                        <Text color="white" fontSize="sm" fontWeight={600}>
                          {breakpointMd ? item.player.lastname : item.player.lastname.split(" ")[0]}
                        </Text>
                      </HStack>
                    </Td>
                    <Td p={3} fontSize="sm" color="white" textAlign="center" borderColor="gray.500" _groupHover={{ fontWeight: 600 }}>{goals}</Td>
                    <Td p={3} fontSize="sm" color="white" textAlign="center" borderColor="gray.500" _groupHover={{ fontWeight: 600 }}>{penalties}</Td>
                  </motion.tr>
                )
              })}
            </AnimatePresence>
          </Tbody>

          {visibleRows?.length > 4 && (
            <Tbody>
              <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Td colSpan={12} textAlign="center" border="none">
                  <Button
                    onClick={toggleExpansion}
                    variant="link"
                    color="white"
                    _hover={{ color: "yellow.400", textDecoration: "underline" }}
                    rightIcon={expanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
                  >
                    {expanded ? "Ver menos" : "Ver más"}
                  </Button>
                </Td>
              </motion.tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default TopScorers
