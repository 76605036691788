import { Statistic, Statistics } from "../../../types/match";

function getNumericValue(item: Statistic) {
  if (item.value === null) {
    return item.value = "0";
  };

  if (typeof item.value === "string" && item.value.includes('%')) {
    return parseFloat(item.value);
  };

  return parseFloat(item.value);
};

export function getTypeStatistic(datos: Statistics[], type: string) {
  const result = datos.reduce((result: Statistic[], item: Statistics) => {
    const statistics = item.statistics.filter((statistic: Statistic) => statistic.type === type);
    return [...result, ...statistics];
  }, []);

  const max = result.reduce((prev: Statistic, curr: Statistic) => {
    const prevValue = getNumericValue(prev);
    const currValue = getNumericValue(curr);
    return (currValue > prevValue ? curr : prev);
  });

  const updatedData = result.map((item: Statistic) => {
    const isMax = getNumericValue(item) === getNumericValue(max);
    return { ...item, max: isMax };
  });

  return updatedData;
};