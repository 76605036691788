import { Box, HStack, Text } from '@/components/Chakra'
import { Statistic, Statistics } from '@/types/match'
import { getTypeStatistic } from '@/utilities/pages/match/getTypeStatistic'

const Types = ({ statistics, type, typeTranslated }: { statistics: Statistics[], type: string, typeTranslated: string }) => {
  const DividerType = () => {
    return (
      <Box width={{ base: "full", md: "50%", lg: "30%" }}>
        <Text color="white" fontWeight={600} textAlign="center">{typeTranslated}</Text>
      </Box>
    )
  }

  return (
    <HStack justifyContent="center" divider={<DividerType />}>
      {getTypeStatistic(statistics, type).map((item: Statistic, index: number) => {
        const value = item.value
        const max = item.max

        return (
          <Text key={index} color={max ? "green.400" : "white"} fontWeight={max ? 600 : 400} flexBasis="45%" textAlign={index === 0 ? "right" : "left"}>{value}</Text>
        )
      })}
    </HStack>
  )
}

export default Types