'use client'

import Image from 'next/image'
import { useState } from 'react'
import { Box, Button, Flex, HStack, Square, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from '@/components/Chakra'
import { AnimatePresence, motion } from 'framer-motion'
import Icon from '@/components/Icon'
import MatchDetails from '@/components/pages/home/schedule/MatchDetails'
import { Result } from '@/types/results'
import { useLastResults } from '@/hooks/useLastResults'
import { getCurrentDate, getHour } from '@/utilities/calendar-dates'

const Schedule = () => {
  const [idFixture, setIdFixture] = useState<number | undefined>(undefined)
  const [expanded, setExpanded] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [breakpointMd] = useMediaQuery("(min-width: 768px)", { ssr: true, fallback: true })

  const { lastResults } = useLastResults()

  const visibleRows = expanded ? lastResults : lastResults?.slice(0, 6)

  function toggleExpansion() {
    setExpanded(!expanded)
  }

  function handleOnClick(id: number) {
    setIdFixture(id)
    onOpen()
  }

  return (
    <Stack as="section" bgColor="gray.700" border="1px solid" borderColor="gray.500" borderRadius="lg" overflow="hidden" spacing={0}>
      <Box borderBottom="1px solid" borderColor="gray.500" bgColor="gray.800" p={3}>
        <Text fontSize={{ base: "md", md: "lg" }} fontWeight={600} color="white" textAlign="center">Últimos resultados</Text>
      </Box>

      <TableContainer>
        <Table overflow="hidden">
          <Thead>
            <Tr>
              <Th
                scope="col"
                width="20%"
                bgColor="gray.600"
                p={4}
                fontSize={{ base: "xs", md: "sm" }}
                color="white"
                fontWeight={400}
                textTransform="capitalize"
                textAlign="center"
                borderColor="gray.500"
              >Fecha</Th>
              <Th
                scope="col"
                bgColor="gray.600"
                p={4}
                fontSize={{ base: "xs", md: "sm" }}
                color="white"
                fontWeight={400}
                textTransform="capitalize"
                textAlign="center"
                borderColor="gray.500"
              >Partido</Th>
            </Tr>
          </Thead>

          <Tbody>
            <AnimatePresence initial={false}>
              {visibleRows?.map((match: Result, index: number) => {
                const date = getCurrentDate(match.fixture.date)
                const hour = getHour(match.fixture.date, "HH:mm")

                return (
                  <motion.tr
                    key={index}
                    role="group"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    whileHover={{ scale: 1.03, backgroundColor: "#1A202C" }}
                    onClick={() => handleOnClick(match.fixture.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td p={3} fontSize={{ base: "xs", md: "sm" }} borderColor="gray.500">
                      <HStack justifyContent="center">
                        <Text textAlign="center" fontSize={{ base: "xs", md: "sm" }} color="white" textTransform="capitalize">{date}</Text>
                        <Text textAlign="center" fontSize={{ base: "xs", md: "sm" }} color="white" textTransform="capitalize">{hour}</Text>
                      </HStack>
                    </Td>

                    <Td p={3} borderColor="gray.500">
                      <HStack justifyContent="center" divider={<Flex border="none" color="white" fontWeight={600} _groupHover={{ color: "yellow.400" }}>:</Flex>}>
                        <HStack flexBasis="50%" spacing={{ base: 2, md: 4 }} justifyContent="flex-end">
                          <Text fontSize={{ base: "xs", md: "sm" }} color="white" textTransform={{ base: "uppercase", md: "uppercase" }}>{match.teams.home.name.slice(0,3)}</Text>
                          <Box as="picture" maxWidth={breakpointMd ? "25px" : "20px"}>
                            <Image src={match.teams.home.logo} width={breakpointMd ? 25 : 20} height={breakpointMd ? 25 : 20} alt={`Logo ${match.teams.home.name}`} />
                          </Box>
                          <Square fontSize={{ base: "sm", md: "md" }} color="white" fontWeight={600}  _groupHover={{ color: "yellow.400" }}>
                            {match.goals.home}
                          </Square>
                        </HStack>

                        <HStack flexBasis="50%" spacing={{ base: 2, md: 4 }} justifyContent="flex-start">
                          <Square fontSize={{ base: "sm", md: "md" }} color="white" fontWeight={600} _groupHover={{ color: "yellow.400" }}>
                            {match.goals.away}
                          </Square>
                          <Box as="picture" maxWidth={breakpointMd ? "25px" : "20px"}>
                            <Image src={match.teams.away.logo} width={breakpointMd ? 25 : 20} height={breakpointMd ? 25 : 20} alt={`Logo ${match.teams.away.name}`} />
                          </Box>
                          <Text fontSize={{ base: "xs", md: "sm" }} color="white" textTransform={{ base: "uppercase", md: "uppercase" }}>{match.teams.away.name.slice(0,3)}</Text>
                        </HStack>
                      </HStack>
                    </Td>
                  </motion.tr>
                )
              })}
            </AnimatePresence>
          </Tbody>

          <Tbody>
            <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Td colSpan={12} textAlign="center" border="none" py={4}>
                <Button
                  onClick={toggleExpansion}
                  variant="link"
                  color="white"
                  _hover={{ color: "yellow.400", textDecoration: "underline" }}
                  rightIcon={expanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
                >
                  {expanded ? "Ver menos" : "Ver más"}
                </Button>
              </Td>
            </motion.tr>
          </Tbody>
        </Table>
      </TableContainer>

      <MatchDetails onClose={onClose} isOpen={isOpen} idFixture={idFixture} />
    </Stack>
  )
};

export default Schedule;
