'use client'

import Image from 'next/image'
import { Box, Center, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Heading, HStack, Stack, Text, useMediaQuery, UseModalProps } from '@/components/Chakra'
import { GiWhistle } from 'react-icons/gi'
import { MdStadium } from 'react-icons/md'
import { LuCalendarDays } from 'react-icons/lu'
import LastMatches from '@/components/LastMatches'
import Types from '@/components/pages/match/statistics/Types'
import { useMatch } from '@/hooks/useMatch'
import { useResults } from '@/hooks/useResults'
import { getDateWithHour } from '@/utilities/calendar-dates'

type MatchDetailsProps = {
  idFixture: number | undefined
}

const MatchDetails = ({ onClose, isOpen, idFixture }: MatchDetailsProps & UseModalProps) => {
  const [breakpointLg] = useMediaQuery("(min-width: 992px)", { ssr: true, fallback: true })

  const { match } = useMatch(idFixture)
  const { results } = useResults({})

  const playerWithHighestRating = match?.players.map(( item: any ) => item.players).flat().reduce((maxPlayer: any, currentPlayer: any) => {
    const currentRating = parseFloat(currentPlayer.statistics[0].games.rating);
    const maxRating = parseFloat(maxPlayer.statistics[0].games.rating);
  
    if (currentRating > maxRating) {  
      return currentPlayer;
    }
  
    return maxPlayer;
  });

  if (!match) return null

  return (
    <Drawer size={{ lg: "sm" }} placement={breakpointLg ? "right" : "bottom"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent
        maxHeight={{ base: "calc(100vh - 15%)", lg: "100%" }}
        bgColor="gray.800"
        border="1px solid"
        borderRight={{ lg: "none" }}
        borderTop={{ lg: "none" }}
        borderBottom="none"
        borderColor="green.400"
        borderTopLeftRadius="xl"
        borderTopRightRadius={{ base: "xl", lg: "none" }}
        borderBottomLeftRadius={{ lg: "xl" }}
      >
        <DrawerHeader borderBottomWidth="1px" borderColor="gray.400">
          <HStack justifyContent="space-between">
            <Stack width="full" alignItems="center" flexBasis="33.33%" spacing={0}>
              <Box as="picture" p={2}>
                <Image src={match.teams.home.logo} width={breakpointLg ? 50 : 30} height={breakpointLg ? 50 : 30} alt={`Logo ${match.teams.home.name}`} />
              </Box>
              <Text as="h2" fontSize={{ base: "xs", md: "md" }} color="white" fontWeight={600} textAlign="center">{match.teams.home.name}</Text>
            </Stack>

            <Stack width="full" alignItems="center" flexBasis="33.33%" spacing={0}>
              <HStack divider={<Flex border="none" color="white" fontWeight={600}>:</Flex>}>
                <Text fontSize="3xl" color="white" fontWeight={600}>{match.goals.home}</Text>
                <Text fontSize="3xl" color="white" fontWeight={600}>{match.goals.away}</Text>
              </HStack>

              <Text fontSize="sm" color="gray.400">Final</Text>
            </Stack>

            <Stack width="full" alignItems="center" flexBasis="33.33%" spacing={0}>
              <Box as="picture" p={2}>
                <Image src={match.teams.away.logo} width={breakpointLg ? 50 : 30} height={breakpointLg ? 50 : 30} alt={`Logo ${match.teams.away.name}`} />
              </Box>
              <Text as="h2" fontSize={{ base: "xs", md: "md" }} color="white" fontWeight={600} textAlign="center">{match.teams.away.name}</Text>
            </Stack>
          </HStack>
        </DrawerHeader>

        <DrawerBody pb={12}>
          <Stack spacing={4}>
            <Stack pt={5}>
              <Types statistics={match.statistics} type="Ball Possession" typeTranslated="Posesión" />
            </Stack>

            <Stack spacing={4}>
              <Heading as="h4" color="white" fontSize="md" borderBottom="1px solid" borderColor="gray.600" pt={4} pb={{ base: 1, md: 2 }} textTransform="uppercase">Mejor jugador</Heading>

              <HStack>
                <Box as="picture" borderRadius="50%" overflow="hidden">
                  <Image src={playerWithHighestRating.player.photo} width={50} height={50} alt={`Foto jugador ${playerWithHighestRating.player.name}`} />
                </Box>
                <Stack spacing={0}>
                  <Text fontSize="sm" color="white" fontWeight={600}>{playerWithHighestRating.player.name}</Text>
                  <Text fontSize="sm" color="gray.400">Puntaje: {playerWithHighestRating.statistics.map((player: any) => { return player.games.rating})}</Text>
                </Stack>
              </HStack>
            </Stack>

            <Stack spacing={4}>
              <Heading as="h4" color="white" fontSize="md" borderBottom="1px solid" borderColor="gray.600" pt={4} pb={{ base: 1, md: 2 }} textTransform="uppercase">Información del partido</Heading>

              <HStack>
                <Center border="1px solid" borderColor="gray.500" borderRadius="50%" w={8} h={8}>
                  <LuCalendarDays color="white" />
                </Center>
                <Stack spacing={0}>
                  <Text fontSize="xs" color="white" fontWeight={600}>Inicio</Text>
                  <Text fontSize="xs" color="gray.400" textTransform="capitalize">{getDateWithHour(match.fixture.date, "dddd, DD MMMM YYYY - HH:mm")}</Text>
                </Stack>
              </HStack>

              <HStack>
                <Center border="1px solid" borderColor="gray.500" borderRadius="50%" w={8} h={8}>
                  <GiWhistle color="white" />
                </Center>
                <Stack spacing={0}>
                  <Text fontSize="xs" color="white" fontWeight={600}>Árbitro</Text>
                  <Text fontSize="xs" color="gray.400">{match.fixture.referee}</Text>
                </Stack>
              </HStack>

              <HStack>
                <Center border="1px solid" borderColor="gray.500" borderRadius="50%" w={8} h={8}>
                  <MdStadium color="white" />
                </Center>
                <Stack spacing={0}>
                  <Text fontSize="xs" color="white" fontWeight={600}>Ubicación</Text>
                  <Text fontSize="xs" color="gray.400">{match.fixture.venue.name}</Text>
                </Stack>
              </HStack>
            </Stack>

            <Stack spacing={4}>
              <Heading as="h4" color="white" fontSize="md" borderBottom="1px solid" borderColor="gray.600" pt={4} pb={{ base: 1, md: 2 }} textTransform="uppercase">Últimos resultados</Heading>

              <HStack justifyContent="space-between">
                <HStack>
                  <Box as="picture">
                    <Image src={match.teams.home.logo} width={20} height={20} alt={`Logo ${match.teams.home.name}`} />
                  </Box>
                  <Text fontSize="sm" color="white">{match.teams.home.name}</Text>
                </HStack>
                <LastMatches results={results} team={match.teams.home.name} />
              </HStack>

              <HStack justifyContent="space-between">
                <HStack>
                  <Box as="picture">
                    <Image src={match.teams.away.logo} width={20} height={20} alt={`Logo ${match.teams.away.name}`} />
                  </Box>
                  <Text fontSize="sm" color="white">{match.teams.away.name}</Text>
                </HStack>

                <LastMatches results={results} team={match.teams.away.name} />
              </HStack>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MatchDetails