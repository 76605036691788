import _ from 'lodash'
import useSWR from 'swr'
import { getCurrentYear } from '@/utilities/calendar-dates'
import { LeaguesAvailable } from '@/types/leagues'

const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => res.json())
const currentYear = getCurrentYear()

export const useLastResults = () => {
  const { data } = useSWR(`/api/lastResults?season=${currentYear}&league=${LeaguesAvailable.PRIMERA_DIVISION_ID}`, fetcher);

  return {
    lastResults: _.get(data, 'data.response')
  }
}