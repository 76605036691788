import moment from 'moment'
import { Result } from '@/types/results'

// Should return array with the last five results
export function getLastResults(results: Result[], team: string): Result[] {
  if (!results || !team) return [];

  const lastFiveResults = results?.filter((item: Result) => item.teams.home.name === team || item.teams.away.name === team)
  .sort((a, b) => moment(b.fixture.date).diff(moment(a.fixture.date)))
  .slice(0, 5);
  return lastFiveResults;
};