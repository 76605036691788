import useSWR from 'swr'
import _ from 'lodash'
import { LeaguesAvailable } from '@/types/leagues'
import { getCurrentYear } from '@/utilities/calendar-dates'

const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => res.json())
const currentYear = getCurrentYear()

export const useTopScorers = () => {
  const { data } = useSWR(`/api/topScorers?league=${LeaguesAvailable.PRIMERA_DIVISION_ID}&season=${currentYear}`, fetcher);

  return {
    topScorers: _.get(data, 'data.response')
  }
}