import useSWR from 'swr'
import _ from 'lodash'

const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => res.json())

export function useMatch(id: number | undefined) {
  const { data } = useSWR(`/api/match?id=${id}`, fetcher)

  return {
    match: _.get(data, 'data.response[0]')
  }
}