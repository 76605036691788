'use client'

import Image from 'next/image'
import NextLink from 'next/link'
import { Box, Circle, HStack, LinkBox, LinkOverlay, Stack, Text, useMediaQuery } from '@/components/Chakra'
import Icon from '@/components/Icon'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Result } from '@/types/results'
import { useNextFixtures } from '@/hooks/useNextFixtures'
import { getCurrentDate, getHour } from '@/utilities/calendar-dates'

const CarouselFixtures = () => {
  const [breakpointMd] = useMediaQuery("(min-width: 768px)", { ssr: true, fallback: true });

  const { nextFixtures } = useNextFixtures();

  nextFixtures?.sort((a: Result, b: Result) => new Date(a.fixture?.date).getTime() - new Date(b.fixture?.date).getTime());
  
  return (
    <Swiper
      slidesPerView={3.5}
      spaceBetween={7}
      slidesPerGroup={2}
      centerInsufficientSlides
      grabCursor={true}
      breakpoints={{
        768: {
          slidesPerView: 5.5,
          spaceBetween: 8
        },
        992: {
          slidesPerView: 7.5,
          spaceBetween: 11
        },
        1280: {
          slidesPerView: 10,
          spaceBetween: 14
        }
      }}
    >
      {!breakpointMd && (
        <SwiperSlide>
          <LinkBox>
            <Stack height="88px" alignItems="center" justifyContent="center" spacing={1} bgColor="gray.800" borderRadius="md" p={2.5}>
              <Circle size="25px" color="green.500">
                <Icon name="repeat" />
              </Circle>
              <Stack spacing={0}>
                <Text fontSize="sm" fontWeight={600} color="white" textAlign="center">
                  <LinkOverlay as={NextLink} href="/transferencias">Transfer.</LinkOverlay>
                </Text>
                <Text fontSize="xs" color="gray.400" textAlign="center">Ver todo</Text>
              </Stack>
            </Stack>
          </LinkBox>
        </SwiperSlide>
      )}

      {nextFixtures?.map((match: Result, index: number) => {
        const hour = getHour(match.fixture.date, "HH:mm")
        const live = match.fixture.status.short === "1H" || match.fixture.status.short === "2H"

        return (
          <SwiperSlide key={index}>
            <Stack justifyContent="center" spacing={1} bgColor="gray.800" borderRadius="md" py={2.5} px={2} boxShadow={live ? "inset 0px 0 0px 3px #48BB78" : "none"}>
              <HStack justifyContent="center">
                <Box as="picture">
                  <Image src={match.teams.home.logo} width={25} height={25} alt={`Logo home team - ${match.teams.home.logo}`} priority />
                </Box>
                <Box as="picture">
                  <Image src={match.teams.away.logo} width={25} height={25} alt={`Logo away team - ${match.teams.away.logo}`} priority />
                </Box>
              </HStack>
              <Stack spacing={0}>
                <Text fontSize="sm" fontWeight={600} color={live ? "green.400" : "white"} textAlign="center" textTransform="capitalize">{live ? "En vivo" : getCurrentDate(match.fixture.date)}</Text>
                <Text fontSize="xs" color="gray.400" textAlign="center">{hour}</Text>
              </Stack>
            </Stack>
          </SwiperSlide>
        )
      })}
    </Swiper>
  );
};

export default CarouselFixtures;