
type Game = {
  name: string;
  winner: boolean | null;
}

type Games = {
  home: Game
  away: Game
}

type Status = {
  short: string
}

/**
 * Should get background-color if team won, lost or tied, or the match was suspended
 * @param team
 * @param game
 * @param status
 * @returns
 */
export function getColorMatchResult(team: string, game: Games, status: Status): string {  
  const { home, away } = game;
  const { short } = status;

  if (short === "PST") {
    return "yellow.500";
  }

  if (team === home?.name && home?.winner === true ) {
    return "green.500";
  }

  if (team === away?.name && away?.winner === true) {
    return "green.500";
  }

  if (team === home?.name && home?.winner === false) {
    return "red.500";
  }
  
  if (team === away?.name && away?.winner === false) {
    return "red.500";
  }
  
  if (home?.winner === null && away?.winner === null) {
    return "gray.500";
  }

  return "transparent";
};

/**
 * Should get result if team won, lost or tied, or the match was suspended
 * @param team
 * @param game
 * @param status
 * @returns 
 */
export function getTextMatchResult(team: string, game: Games, status: Status): string {
  const { home, away } = game;
  const { short } = status;

  if (short === "PST") {
    return "S";
  }

  if (team === home?.name && home?.winner === true) {
    return "G";
  }
  
  if (team === away?.name && away?.winner === true) {
    return "G";
  }

  if (team === home?.name && home?.winner === false) {
    return "P";
  }

  if (team === away?.name && away?.winner === false) {
    return "P";
  }

  if (home?.winner === null && away?.winner === null) {
    return "E";  
  }

  return "X";
};