import useSWR from "swr";
import _ from "lodash";
import { getCurrentYear } from "@/utilities/calendar-dates";
import { LeaguesAvailable } from "@/types/leagues";

type Standings = {
  league?: number;
  season?: number;
};

const fetcher = (...args: Parameters<typeof fetch>) =>
  fetch(...args).then((res) => res.json());
const currentYear = getCurrentYear();

export function useStandings({
  league = LeaguesAvailable.PRIMERA_DIVISION_ID,
  season = currentYear,
}: Standings) {
  const { data } = useSWR(
    `/api/standings?league=${league}&season=${season}`,
    fetcher
  );

  if (league === LeaguesAvailable.NACIONAL_B_ID) {
    return {
      zoneA: _.get(data, "data.response[0].league.standings[0]"),
      zoneB: _.get(data, "data.response[0].league.standings[1]"),
    };
  }

  if (league === LeaguesAvailable.METROPOLITANA_B_ID) {
    return {
      apertura: _.get(data, "data.response[0].league.standings[0]"),
      clausura: _.get(data, "data.response[0].league.standings[1]"),
    };
  }

  return {
    primeraDivision: _.get(data, "data.response[0].league.standings[0]"),
  };
}
