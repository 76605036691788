import moment from "moment"
import "moment/locale/es"

export function getCurrentDate(date: string): string {
  return moment(date).calendar({
    sameDay: '[Hoy]',
    nextDay: '[Mañana]',
    nextWeek: 'dddd',
    lastDay: '[Ayer]',
    lastWeek: 'DD ddd',
    sameElse: 'DD ddd'
  })
}

export function getDate(date: string, format: string): string {
  return moment(date).locale('es').format(format)
}

export function getHour(date: string, format: string): string {
  return moment(date).locale('es').format(format)
}

export function getDateWithHour(date: string, format: string): string {
  return moment(date).locale('es').format(format)
}

/**
 * Should return the current year in String.
 * Uses Moment lib.
 * @returns string
 */
export function getCurrentYear(): number {
  return moment().year()
}

export function getYear(date: string): number {
  const stringDate = moment(date).locale('es').format("YYYY")
  const formatDate = parseInt(stringDate)
  return formatDate
}
